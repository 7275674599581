<template>
  <div>
    <full-page ref="fullpage" :options="options">
      <div class="section bg1">
        <div class="box1">
          <img src="../../assets/tlimg/1.png" alt="">
        </div>
      </div>
      <div class="section bgtl">
        <div class="box4">
          <timeLine></timeLine>
        </div>
      </div>
      <div class="section bg2">
        <div class="box2">
          <img src="../../assets/tlimg/2.png" alt="">
        </div>
      </div>
      <div class="section bg3">
        <div class="box3">
          <img src="../../assets/tlimg/3.png" alt="">
        </div>
      </div>
      <div class="section">
        <div class="box5">
          <Footer></Footer>
        </div>
      </div>
    </full-page>
     <div class="sidebar4 flex" @click="backTop">
      <img src="../../assets/images/sidebar4.png" />
    </div>
  </div>
</template>

<script>
import timeLine from "./timeLine.vue";
import Footer from "../../layout/components/footer.vue";
// import { BusEmit } from "@/utils/eventBus.js";
export default {
  components: {
    timeLine,
    Footer
  },
  name: "",
  data() {
    return {
      options: {
        licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
        afterLoad: this.afterLoad,
        scrollOverflow: true,
        scrollBar: false,
        menu: "#menu",
      }
    };
  },
  methods: {
    // handc() {
    //   BusEmit("Top", "setBlackBg");
    // },
    // setWhite() {
    //   BusEmit("Top", "setWhiteBg");
    // },
    // afterLoad(item, index) {
    //   let page = index.index;
    //   if (page == 5) {
    //     this.handc();
    //   } else {
    //     this.setWhite();
    //   }
    // }
       backTop() {
      this.$refs.fullpage.api.moveTo(1, 0)
    }
  }
};
</script>

<style lang="scss" scoped>
.bgtl {
  background: url("~@/assets/tlimg/bg.png") center center no-repeat;
  background-size: 100% 100%;
}
// .bg1{
//    background: url("~@/assets/tlimg/1.png") center center no-repeat;
// }
// .bg2{
//    background: url("~@/assets/tlimg/2.png") center center no-repeat;
// }
// .bg3{
//    background: url("~@/assets/tlimg/3.png") center center no-repeat;
// }
.box1,
.box2,
.box3 {
  height: 100%;
}
.box4 {
  min-width: 1500px;
  height: 100%;
}
.section {
  width: 1920px;
  img {
    width: 100vw;
    height: 100vh;
  }
}
.sidebar4 {
  position: fixed;
  z-index: 1000;
  width: 50px;
  right: 60px;
  bottom: 66px;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-radius: 50%;
  background-color: $blue;
  border: 2px solid #fff;
  cursor: pointer;
}
</style>
