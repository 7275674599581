<template>
  <div class="container" :style="changeThemeColor">
    <div class="titleA">
      <div class="titleO">成长历程</div>
      <div class="titleT">十载深耕地产</div>
      <div class="titleTh">数字化营销战略实施的一流供应商</div>
    </div>
    <div class="timeline" :class="{mt: !carouseIsShow}">
      <div class="line full-width"></div>
      <div class="timeline-container">
        <div class="item posL" @click="go(prevIndex, 'prev')">
          <!-- <button
            :class="{ 'item-disabled': !loop && currentIndex === 0 }"
            class="item-prev"
            :disabled="!loop && currentIndex === 0"
          >
            <span class="iconfont icon-jiantouarrow506"></span>
          </button> -->
          <img src="../../assets/tlimg/left.png" >
        </div>
        <div class="item">
          <div
            class="list"
            v-for="(item, index) in timelineList"
            :key="item.id"
            @click="go(index, '')"
            @mouseover="handleMouseOver(item.text, index)"
            @mouseleave="handleMouseLeave(item.text, index)"
            :style="{
              transform: `translateX(${translateNum}%)`,
              width: `${timelineWidth}%`,
            }"
          >
            <div class="circle">
            <div class="circleZX" :class="{ active: index === currentIndex }"></div>
            </div>
            <div class="text">
              {{ item.text }}
            </div>
            <div
              class="line"
              :class="{
                on: index === currentIndex && onIsActive,
                go: index === currentIndex && !onIsActive,
              }"
            ></div>
            
            <!-- <div ref="tip" class="tip">
              {{ item.text }}
              <div class="triangle"></div>
            </div> -->
          </div>
        </div>
        <div class="item posR" @click="go(nextIndex, 'next')">
          <!-- <button
            class="item-next"
            :disabled="!loop && currentIndex === timelineList.length - 1"
            :class="{
              'item-disabled':
                !loop && currentIndex === timelineList.length - 1,
            }"
          >
            <span class="iconfont icon-jiantouarrow484"></span>
          </button> -->
          <img src="../../assets/tlimg/right.png" >
        </div>
      </div>
    </div>
    <div class="full-width slider" v-if="carouseIsShow">
      <transition-group
        :enter-active-class="enterActiveClass"
        >
        <div
          class="slider-box"
          v-for="(item, index) in carouselList"
          :key="item.id"
          v-show="index === currentIndex"
          @mouseover="handleSliderMouseOver"
          @mouseleave="handleSliderMouseLeave"
        >
          <div class="slider-box__item boxL">
            <div class="yearName">
              <div class="yearNameY">{{ timelineList[index].text }}</div>
              <div class="yearNameYs">YEAR</div>
            </div>
            <div class="yearNameLine"></div>
            <div class="yearNameB">{{item.brief}}</div>
          </div>
          <div class="slider-box__item boxR">
            <div class="item-title">{{ item.title }}</div>
            <div class="item-info">{{ item.info }}</div>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    timelineList: {
      type: Array,
      default() {
        return [
          {
            id: 1,
            text: "2012"
          },
          {
            id: 2,
            text: "2013"
          },
          {
            id: 3,
            text: "2014"
          },
          {
            id: 4,
            text: "2015"
          },
          {
            id: 5,
            text: "2016"
          },
          {
            id: 6,
            text: "2017"
          },
          {
            id: 7,
            text: "2018"
          },
          {
            id: 8,
            text: "2019"
          },
          {
            id: 9,
            text: "2020"
          }
        ];
      }
    },
    autoPlay: {
      type: Boolean,
      default: false
    },
    loop: {
      type: Boolean,
      default: true
    },
    carouselList: {
      type: Array,
      default() {
        return [
          {
            id: 1,
            title: "公司成立",
            info: "2012枫林云在深圳正式成立，开始研发智能数据化营销管理工具系统，为住宅商业等营销系统打下坚实基础。",
            brief:"成立年"
          },
          {
            id: 2,
            title: "电信-智慧生活",
            info: "2013年3月，中标湖北电信智慧生活项目，为电信打造移动化数字营销系统，实现电信营销业务的线上转型。",
            brief:"艰攻"
          },
          {
            id: 3,
            title: "福彩-掌上福彩",
            info: "2014年1月，我司自主研发的乐猫彩票入驻湖北福彩，为实现购彩业务的线上化营销打造开放型系统平台。",
            brief:"突破年"
          },
          {
            id: 4,
            title: "万科-销售家",
            info: "2015年6月，我司自主研发的案场管家被万科整体收购升级迭代为客户平台销售家，开启万科移动销售新时代。",
            brief:"研发年"
          },
          {
            id: 5,
            title: "龙光-回款系统",
            info: "2016年3月，联手龙光地产打造数字化回款监测和管理系统,为龙光地产业务的回款全面信息化转型奠定基础。",
            brief:"发展年"
          },
          {
            id: 6,
            title: "万科-置业神器",
            info: "2017年3月，万科移动销售率先布局微信小程序进入全民营销新时代，我司参与置业神器全周期的立项竞标开发迭代发行。",
            brief:"精耕年"
          },
          {
            id: 7,
            title: "印力-商业营销",
            info: "2018年5月，印商业全面布局商业地产营销管理，首创scrm云营销模型，我司全程参与印享星c端b端开发及发行。",
            brief:"布局"
          },
          {
            id: 8,
            title: "融创-移动销售",
            info: "2019年6月，融创立项移动销售2.0变革,布局幸福通小程序,重新设计融客+、融客+展厅、融客+ipad接待台、客储后台等。",
            brief:"创新年"
          },
          {
            id: 9,
            title: "招商-数据中心",
            info: "2020年8月，我司为招商地产布局开发招商统一数据中心平台,为各系统及移动销售提供数据和统一接口支撑。",
            brief:"拓展年"
          }
        ];
      }
    },
    timelineNum: {
      type: Number,
      default: 4
    },
    timelineWidth: {
      type: Number,
      default: 100
    },
    themeColor: {
      type: String,
      default: "#154F51"
    },
    enterActiveClass: {
      type: String,
      default: "animate__animated animate__fadeInRight"
    },
    mouseEvent: {
      type: Boolean,
      default: false
    },
    carouseIsShow: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      currentIndex: 0,
      timer: null,
      timer2: null,
      onIsActive: true,
      translateNum: 0
    };
  },
  computed: {
    prevIndex() {
      if (!this.loop) {
        if (this.currentIndex == 0) {
          return 0;
        } else {
          return this.currentIndex - 1;
        }
      } else {
        if (this.currentIndex == 0) {
          return this.timelineList.length - 1;
        } else {
          return this.currentIndex - 1;
        }
      }
    },
    nextIndex() {
      if (!this.loop) {
        if (this.currentIndex == this.timelineList.length - 1) {
          return this.timelineList.length - 1;
        } else {
          return this.currentIndex + 1;
        }
      } else {
        if (this.currentIndex == this.timelineList.length - 1) {
          return 0;
        } else {
          return this.currentIndex + 1;
        }
      }
    },
    changeThemeColor() {
      return {
        "--theme-color": this.themeColor
      };
    }
  },
  methods: {
    go(index, str) {
      this.currentIndex = index;
      this.onIsActive = true;
      if (this.autoPlay) {
        this.setGo();
        this.setOnActive();
      }
      this.calcTranslateNum(str);
      this.$emit(
        "getCurrentItem",
        index,
        this.timelineList[index],
        this.carouselList[index]
      );
    },
    setOnActive() {
      clearTimeout(this.timer2);
      this.timer2 = setTimeout(() => {
        this.onIsActive = false;
      }, 2800);
    },
    setGo() {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.go(this.nextIndex);
        this.calcTranslateNum("next");
      }, 3600);
    },
    calcTranslateNum(str) {
      const length = this.timelineList.length - 1;
      if (str === "next") {
        if (this.currentIndex >= this.timelineNum) {
          this.translateNum = (length - this.timelineNum) * -this.timelineWidth;
        }
        if (this.currentIndex >= 2*this.timelineNum) {
          this.translateNum = (length - this.timelineNum) * -this.timelineWidth * 2;
        }
        if (this.currentIndex === 0) {
          this.translateNum = 0;
        }
      } else if (str === "prev") {
        if (this.currentIndex < this.timelineNum) {
          this.translateNum = 0;
          return;
        }
        if (this.currentIndex >= this.timelineNum) {
          this.translateNum = (length - this.timelineNum) * -this.timelineWidth;
        }
        if (this.currentIndex >= 2*this.timelineNum) {
          this.translateNum = (length - this.timelineNum) * -this.timelineWidth * 2;
        }
        // this.translateNum += this.timelineWidth;
      } else {
        if (this.currentIndex+1 < this.timelineNum) {
          this.translateNum = 0;
          return;
        }
        if (this.currentIndex+1 >= this.timelineNum) {
          this.translateNum =
            (length - 1 - this.timelineNum) * -this.timelineWidth;
        }
        if (this.currentIndex+2 >= 2*this.timelineNum) {
          this.translateNum = 
            (length - 2 - this.timelineNum) * -this.timelineWidth * 2 - this.timelineWidth;
        }
        // this.translateNum += this.timelineWidth;
      }
      // console.log(this.currentIndex,this.translateNum,this.timelineNum,"多少",length,this.timelineWidth)
    },
    handleMouseOver(text, index) {
      if (text.length >= 16) {
        this.$refs.tip[index].style = "display: block;";
      }
    },
    handleMouseLeave(text, index) {
      if (text.length >= 16) {
        this.$refs.tip[index].style = "display: none;";
      }
    },
    handleSliderMouseOver() {
      if (this.autoPlay && this.mouseEvent) {
        clearInterval(this.timer);
        clearTimeout(this.timer2);
      }
    },
    handleSliderMouseLeave() {
      if (this.autoPlay && this.mouseEvent) {
        this.setGo();
        this.setOnActive();
      }
    }
  },
  mounted() {
    if (this.autoPlay) {
      this.setGo();
      this.setOnActive();
    }
    this.$emit("getCurrentItem", 0, this.timelineList[0], this.carouselList[0]);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    clearTimeout(this.timer2);
  },
  watch: {
        currentIndex: function(newVal, oldVal) {
           if(oldVal>newVal&&newVal==3){
              this.translateNum = 0;
            }
        }
    }
};
</script>
<style lang='scss' scoped>
* {
  margin: 0;
  padding: 0;
}
.container {
  width: 100%;
  height: 100%;
  .titleA {
    width: 100%;
    height: 44%;
    text-align: left;
    .titleO {
      padding: 88px 0 0 154px;
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #345ff1;
      box-sizing: border-box;
    }
    .titleT {
      margin: 51px 0 32px 154px;
    }
    .titleTh {
      margin-left: 154px;
    }
    .titleT,
    .titleTh {
      font-size: 48px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
  }
  .slider {
    width: 100%;
    padding: 8% 8% 5% 20%;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      height: 260px;
    }
    .leave-to {
      position: relative;
    }
    .enter-active-class {
      animation: 1s fadeIn linear;
    }
    .slider-box {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &.active {
        animation: 1s fadeIn linear;
      }
      .slider-box__item {
        width: 50%;
        height: 100%;
        &:nth-child(2) {
          // padding-left: 10%;
          // box-sizing: border-box;
          // position: relative;
          .item-title-bg {
            position: absolute;
            color: rgba(0, 0, 0, 0.05);
            right: 0;
            top: 0;
            font-size: 5rem;
            filter: blur(3px);
            opacity: 1;
            width: 100%;
            text-align: center;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .item-title {
            margin-top: 32px;
            margin-bottom: 40px;
          }
          .item-title,
          .item-info {
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #ffffff;
          }
          .item-info{
            line-height: 2;
          }
        }
        img {
          width: 100%;
        }
        .yearName {
          font-size: 56px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #ffffff;
          display: flex;
          align-items: baseline;
          .yearNameYs {
            font-size: 24px;
            margin-left: 30px;
          }
        }
        .yearNameLine {
          width: 85px;
          height: 13px;
          background: #345ff1;
          margin: 50px 0 42px 0;
        }
        .yearNameB {
          font-size: 56px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #ffffff;
          text-align: left;
        }
      }
      .boxL{
        width: 30%;
      }
      .boxR{
        width: 70%;
        text-align: left;
      }
    }
  }
  .timeline {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    &.mt {
      margin-top: 80px;
    }
    .line {
      width: 100%;
      height: 4px;
      background: #154f51;
    }
    .timeline-container {
      width: 100%;
      position: relative;
      .item {
        width: 12.5%;
        float: left;
        text-align: center;
        &:nth-child(2) {
          margin-left: 40%;
          width: 135%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          overflow: hidden;
          padding-top: 150px;
          margin-top: -150px;
        }
        .list {
          position: relative;
          text-align: center;
          cursor: pointer;
          transition: 0.5s all linear;
          display: flex;
          justify-content: center;
          margin-bottom: 10px;
          &:last-child {
            .line {
              display: none;
            }
          }
          .circle {
            position: absolute;
            left: 38%;
            top: -10%;
            width: 34px;
            height: 34px;
            border-radius: 50%;
            margin: -17px auto 0;
            z-index: 2;
            border: #fff 2px solid;
            display: flex;
            align-items: center;
            justify-content: center;
            .circleZX {
              width: 18px;
              height: 18px;
              border-radius: 50%;
              background: #3fa799;
              &.active {
                background-color: #e8923e;
              }
              &:hover {
                background-color: #e8923e;
              }
            }
          }
          .line {
            width: 100%;
            height: 1px;
            position: absolute;
            left: 48%;
            top: -1px;
            opacity: 0;
            z-index: 1;
            &::after {
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              background-color: var(--theme-color);
              animation-fill-mode: both;
              animation-duration: 2.8s;
              animation-timing-function: linear;
            }
          }
          // .line.on {
          //   opacity: 1;
          //   &::after {
          //     animation-name: line_1;
          //   }
          // }
          // .line.go {
          //   opacity: 1;
          //   &::after {
          //     right: 0;
          //     left: auto;
          //     animation-duration: 0.8s;
          //     animation-name: line_2;
          //   }
          // }
          .text {
            position: relative;
            top: -80px;
            left: -6%;
            width: 150px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .tip {
            position: absolute;
            top: -55px;
            padding: 10px 20px;
            border-radius: 5px;
            background-color: var(--theme-color);
            color: #fff;
            width: max-content;
            display: none;
            .triangle {
              width: 0;
              height: 0;
              border-top: 8px solid var(--theme-color);
              border-right: 5px solid transparent;
              border-left: 5px solid transparent;
              position: absolute;
              left: 50%;
              bottom: -8px;
              margin-left: -3.5px;
            }
          }
        }
        .item-prev,
        .item-next {
          width: 45px;
          height: 45px;
          border-radius: 50%;
          color: #909090;
          background-color: #e9e9e9;
          cursor: pointer;
          margin: -24px auto 0;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          outline: none;
          &:hover {
            color: #fff;
            background-color: var(--theme-color);
          }
          &.item-disabled {
            background: #666;
            cursor: no-drop;
          }
        }
      }
      .posL {
        width: 45px;
        height: 45px;
        position: absolute;
        top: -550%;
        right: 15%;
        img{
          width: 14px;
          height: 28px;
        }
      }
      .posR {
        width: 45px;
        height: 45px;
        position: absolute;
        top: -550%;
        right: 10%;
        img{
          width: 14px;
          height: 28px;
        }
      }
    }
  }
}
@keyframes line_1 {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@keyframes line_2 {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
